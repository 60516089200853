import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  addFilteredCouponsList,
  setFilteredCouponsList,
  setNextPage,
  setTotalPage,
} from 'store/filteredCouponsListSlice';
import { setAccessKey } from 'store/couponsAndCategoriesListSlice';
import { GetNewCouponsParams } from '../http/couponsAndCategories/models/search-coupons.model';
import DocumentsHTTPController from '../http/couponsAndCategories/search-coupons-http-controller';

const { getNewCoupons } = DocumentsHTTPController;

const useSearchCoupons = () => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const searchCoupons = useCallback(
    async (params: GetNewCouponsParams) => {
      setIsLoading(true);
      const res = await getNewCoupons(params);
      if (res) {
        dispatch(setAccessKey(res.accessKey));
        // @ts-ignore
        dispatch(setFilteredCouponsList(res.page.content));
        dispatch(setNextPage(res.page.number));
        dispatch(setTotalPage(res.page.totalPages));
      }
      setIsLoading(false);
    },
    [],
  );

  const addedCoupons = useCallback(
    async (params: GetNewCouponsParams) => {
      setIsLoading(true);
      const res = await getNewCoupons(params);
      if (res) {
        // @ts-ignore
        dispatch(addFilteredCouponsList(res.page.content));
        dispatch(setAccessKey(res.accessKey));
      }
      setIsLoading(false);
    },
    [],
  );

  return {
    isLoading,
    searchCoupons,
    addedCoupons,
  };
};

export default useSearchCoupons;

import React from 'react';

import { Button } from '@pofd-front/storybook/dist';
import { useDispatch } from 'react-redux';
import Modal from '../modal/modal';

import styles from './required-auth-modal.module.scss';
import { setModalFlag } from '../../store/modalSlice';

const RequiredAuthModal = () => {
  const dispatch = useDispatch();
  return (
    <Modal
      fullWidth={false}
      headerText="Нужна авторизация"
      onClose={() => {
        dispatch(setModalFlag({ modal: false, def: null }));
      }}
    >
      <div>
        <p className={styles.text}>
          Чтобы получить промокод, войдите или зарегистрируйтесь
        </p>
        <div className={styles['mt-5']}>
          <div>
            <Button
              testId="required_auth_modal-click-1"
              styleType="primary"
              product="ofd"
              size="large"
              label="Войти"
              disabled={false}
              type="button"
              onClick={() => {
                dispatch(setModalFlag({ modal: true, def: 'auth' }));
              }}
            />
          </div>
          <div className={styles['mt-3']}>
            <Button
              testId="required_auth_modal-click-2"
              styleType="secondary"
              product="ofd"
              size="large"
              label="Зарегистрироваться"
              disabled={false}
              type="button"
              onClick={() => {
                dispatch(setModalFlag({ modal: true, def: 'reg' }));
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RequiredAuthModal;

import classNames from 'classnames/bind';

import React, { FC, useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import Search from '../search/search';
import { setUserLogInFlag } from '../../store/userLogInSlice';
import { setModalFlag } from '../../store/modalSlice';
import type { RootState } from '../../store';

import loginIcon from '../../images/icons/login-icon.svg';
import logoImg from '../../images/icons/logo-pcheck.svg';
import userLogo from '../../images/icons/User.svg';
import userLogOutIcon from '../../images/icons/LogOut.svg';

import styles from './app-header.module.scss';

const cx = classNames.bind(styles);

interface IAppHeader {
  currentUrl: string;
  floctoryFlag: boolean;
  smartBannerFlag: boolean;
}

const AppHeader: FC<IAppHeader> = ({
  floctoryFlag,
  smartBannerFlag,
  currentUrl,
}) => {
  const [userDescFlag, setUserDescFlag] = useState(false);
  const [activeHeaderFlag, setActiveHeaderFlag] = useState(true);
  const rootEl = useRef(null);
  const selectUserLogged = (state: RootState) => state.userLogIn;
  const userLogged = useSelector(selectUserLogged);
  const dispatch = useDispatch();

  const { openFlag } = useSelector(
    (state: RootState) => state.mobileSearch,
  );

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/redirect_store') {
      setActiveHeaderFlag(false);
    } else {
      setActiveHeaderFlag(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    const ref: React.RefObject<HTMLElement> = rootEl;
    const onClick = (e: MouseEvent) => {
      if (rootEl.current !== null) {
        if (ref.current && !ref.current?.contains(e.target as Node)) {
          setUserDescFlag(false);
        }
      }
    };
    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, []);

  const userLogOut = () => {
    dispatch(setUserLogInFlag(false));
    setUserDescFlag(false);
    localStorage.removeItem('session_id');
    localStorage.removeItem('session_token');
  };

  const toggleUserFlag = () => {
    setUserDescFlag(!userDescFlag);
  };

  useEffect(() => {
    if (localStorage.session_token) {
      dispatch(setUserLogInFlag(true));
    }
  }, []);

  return (
    <div>
      {activeHeaderFlag ? (
        <header className={cx('header')}>
          <Container fluid="md">
            <div className={cx('wrap')}>
              {!openFlag && (
                <div>
                  {floctoryFlag ? (
                    <a
                      href={`${currentUrl}`}
                      data-testid="header-link-1"
                    >
                      <img src={logoImg} alt="podarochek" />
                    </a>
                  ) : (
                    <a href="/" data-testid="header-nav-link-1">
                      <img src={logoImg} alt="podarochek" />
                    </a>
                  )}
                </div>
              )}
              {!floctoryFlag ? (
                <>
                  <div
                    className={`${styles['mr-lg-3']} ${
                      openFlag ? styles.fullwidth : ''
                    }`}
                  >
                    <div className="search">
                      <Search
                        openMobileSearch={openFlag}
                        smartBannerFlag={smartBannerFlag}
                      />
                    </div>
                  </div>
                  {!openFlag && (
                    <div>
                      {!userLogged.userLogIn ? (
                        <div className={cx('login')}>
                          <div className={`${cx('btn-wrap')} d-flex`}>
                            <button
                              data-testid="header-click-1"
                              onClick={() => {
                                dispatch(
                                  setModalFlag({
                                    modal: true,
                                    def: 'auth',
                                  }),
                                );
                              }}
                              className={`${cx(
                                'auth-btn',
                                'sing-in-btn',
                              )} d-none d-lg-block`}
                              type="button"
                            >
                              <span className="">Войти</span>
                            </button>
                            <button
                              data-testid="header-click-2"
                              onClick={() => {
                                dispatch(
                                  setModalFlag({
                                    modal: true,
                                    def: 'reg',
                                  }),
                                );
                              }}
                              className={cx(
                                'auth-btn',
                                'sing-up-btn',
                              )}
                              type="button"
                            >
                              <span className="d-none d-lg-block">
                                Зарегистрироваться
                              </span>
                              <img
                                src={loginIcon}
                                alt=""
                                className="d-block d-lg-none"
                              />
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="position-relative"
                          ref={rootEl}
                        >
                          <div
                            data-testid="header-click-3"
                            aria-hidden
                            onClick={toggleUserFlag}
                            className={`${cx(
                              'user-head',
                            )} d-flex justify-content-end`}
                            role="button"
                          >
                            <div className={cx('user-icon')}>
                              <img src={userLogo} alt="" />
                            </div>
                          </div>
                          {userDescFlag && (
                            <div className={cx('user-desc')}>
                              <div className={cx('user-logout')}>
                                <button
                                  data-testid="header-click-4"
                                  onClick={userLogOut}
                                  className={cx('user-logout-btn')}
                                  type="button"
                                >
                                  <img src={userLogOutIcon} alt="" />
                                  Выход
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </>
              ) : null}
            </div>
          </Container>
        </header>
      ) : null}
    </div>
  );
};

export default AppHeader;

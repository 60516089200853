export const REG_URL = '/api/v1/registration';
export const REG_CONFIRM_URL = '/api/v1/registration/confirm';
export const LOGIN_URL = '/api/v1/login';
export const LOGIN_CONFIRM_URL = '/api/v1/login/confirm';
export const COUPONS_URL = '/api/v2/coupons';
export const PROMO_URL = '/api/v2/coupons/promocode';
export const COUPONS_FIND = '/api/v2/coupons/find';
export const FIND_POSITION = '/api/v2/reverse-geocoding';
export const SAVE_POSITION = '/api/v2/reverse-geocoding/save';
export const GET_POSITION = '/api/v2/reverse-geocoding/saved';

import React, { FC, ReactChild, useRef } from 'react';

import Overlay from '../overlay/overlay';
import ModalHeader from '../modal-header/modal-header';

import styles from './modal.module.scss';

interface IModal {
  onClose: (
    e:
      | React.KeyboardEvent<HTMLDivElement>
      | React.MouseEvent<HTMLDivElement>
      | MouseEvent
      | KeyboardEvent,
  ) => void;
  headerText: string;
  fullWidth: boolean;
  children: ReactChild;
}

const Modal: FC<IModal> = ({
  children,
  onClose,
  headerText,
  fullWidth,
}) => {
  const rootEl = useRef(null);

  return (
    <div ref={rootEl}>
      <div
        className={`${styles.modal} ${
          fullWidth ? styles.fullwidth : ''
        }`}
      >
        {fullWidth ? null : (
          <ModalHeader onClose={onClose} text={headerText} />
        )}
        <div className={styles.content}>{children}</div>
      </div>
      <Overlay onClose={onClose} />
    </div>
  );
};

export default Modal;

import {
  setErrorStatus,
  setIsLoading,
} from 'store/couponsAndCategoriesListSlice';
import { COUPONS_URL } from 'urls';
import { COUPONS_ALL_ID } from 'utils';
import { setErrorModal } from 'store/errorModalSlice';
import { setModalFlag } from 'store/modalSlice';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

const useCoupons = () => {
  const dispatch = useDispatch();

  const getCouponsAndCategories = useCallback(
    async (
      cityName: string,
      regionCode: string,
      regionName: string,
      // eslint-disable-next-line consistent-return
    ) => {
      try {
        dispatch(setErrorStatus(false));
        dispatch(setIsLoading(true));
        const couponsAndCategoriesResponse = await fetch(
          `${COUPONS_URL}?banner_id=${COUPONS_ALL_ID}&region_code=${regionCode}&region_name=${regionName}&city_name=${cityName}`,
        );
        if (couponsAndCategoriesResponse.status !== 200) {
          dispatch(
            setErrorModal({
              errorCode: couponsAndCategoriesResponse.status,
              fullwidth: true,
              itemId: null,
            }),
          );
          dispatch(setModalFlag({ modal: true, def: 'error' }));
        }
        const couponsAndCategories =
          await couponsAndCategoriesResponse.json();
        localStorage.setItem('access_key_is_distributed', 'false');
        return couponsAndCategories;
      } catch (error) {
        dispatch(
          setErrorModal({
            errorCode: null,
            fullwidth: true,
            itemId: null,
          }),
        );
        dispatch(setModalFlag({ modal: true, def: 'error' }));
        dispatch(setErrorStatus(true));
      } finally {
        dispatch(setIsLoading(false));
      }
    },
    [dispatch],
  );

  return { getCouponsAndCategories };
};

export default useCoupons;

import React, { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';
import styles from './coupon.module.scss';
import { ICoupons } from '../../interfaces';
import { declensionNum } from '../../utils';

interface ICoupon {
  data: ICoupons;
}

const Coupon: FC<ICoupon> = ({ data }) => {
  const deadLine = useCallback((date: string) => {
    const fromNowDate = new Date(date);
    const curDate = new Date();

    const deadline = fromNowDate.getTime() - curDate.getTime();
    const days = Math.floor(deadline / 1000 / 60 / 60 / 24) + 1;
    const hours = Math.floor(deadline / 1000 / 60 / 60) % 24;
    const minutes = Math.floor(deadline / 1000 / 60) % 60;

    switch (true) {
      case minutes === 0 && hours === 0 && days === 0:
        return '<div> Акция закончилась  </div>';
      case days === 0 && hours === 0:
        return (
          <div className={styles.deadline_error}>
            Осталось {minutes}{' '}
            {declensionNum(minutes, ['минута', 'минуты', 'минут'])}{' '}
          </div>
        );
      case days === 0:
        return (
          <div className={styles.deadline_error}>
            {' '}
            {hours % 10 === 1 && hours !== 11
              ? 'Остался'
              : 'Осталось'}{' '}
            {hours} {declensionNum(hours, ['час', 'часа', 'часов'])}{' '}
          </div>
        );
      case days > 0 && days <= 7:
        return (
          <div
            className={`${
              days > 1
                ? styles.deadline_warning
                : styles.deadline_error
            }`}
          >
            {' '}
            {days > 1 ? 'Осталось' : 'Остался'} {days}{' '}
            {declensionNum(days, ['день', 'дня', 'дней'])}{' '}
          </div>
        );
      default:
        return null;
    }
  }, []);

  return (
    <Link
      data-testid="coupon-nav-link-1"
      to={`/gift/${data.couponId}`}
      className={styles.link}
    >
      <div className={styles.card}>
        <div className="top position-relative">
          <div className={styles.pic}>
            <img
              src={data.altLogo ? data.altLogo : data.shopLogoLink}
              alt="coupons logo"
            />
          </div>
          <div className={styles.status}>{data.shopName}</div>
          <div className={styles.divider}>
            <div className={styles.left_divider}>
              <div />
            </div>
            <div className={styles.middle_divider} />
            <div className={styles.right_divider}>
              <div />
            </div>
          </div>
          <div className={styles.bottom}>
            <div>
              <div className={styles.title}>{data.title}</div>
              {data.shortDescription !== '' ? (
                <div className={styles.desc}>
                  {data.shortDescription}
                  <span />
                </div>
              ) : null}
              {data.endDt !== null && (
                <div className={styles.deadline}>
                  {deadLine(data.endDt)}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Coupon;

import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useSearchCoupons from './use-search-coupons';
import { COUPONS_ALL_ID } from '../../utils';
import { RootState } from '../../store';

let isFetching = true;
let currentPage = 0;
let currentParam: string | undefined = '';
let currentTotalPage = 0;

const useScrollSearch = () => {
  const { val } = useParams();
  const { addedCoupons, isLoading } = useSearchCoupons();
  const { totalPage, page } = useSelector(
    (state: RootState) => state.filteredCouponsList,
  );
  const { posObjData } = useSelector(
    (state: RootState) => state.userPosition,
  );
  const { cityName, regionName, regionCode } = posObjData;

  useEffect(() => {
    currentPage = page;
    currentParam = val;
    currentTotalPage = totalPage;
  }, [totalPage, val]);

  const scrollSearch = useCallback(
    (accessKey: string) => {
      if (
        isFetching &&
        currentTotalPage > 1 &&
        currentTotalPage > currentPage + 1
      ) {
        currentPage += 1;
        isFetching = false;
        addedCoupons({
          city_name: cityName,
          region_name: regionName !== undefined ? regionName : '',
          region_code: regionCode,
          page: String(currentPage),
          access_key: accessKey,
          size: '24',
          shopOrTitleName: currentParam,
          banner_id: String(COUPONS_ALL_ID),
        }).finally(() => {
          isFetching = true;
        });
      }
    },
    [totalPage],
  );

  return {
    scrollSearch,
    isLoading,
  };
};

export default useScrollSearch;

import React, { FC, useEffect, useMemo } from 'react';

import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import CouponFilter from '../components/coupon-filter/coupon-filter';
import CouponList from '../components/coupon-list/coupon-list';

const getFilteredCouponByCategories = (
  selectedCategory: number | null,
  listGroup: any,
  list: any,
) => {
  let currentList: any = listGroup;

  if (!selectedCategory) {
    return listGroup;
  }
  currentList = list;

  return currentList.filter((item: any) =>
    item.shopCategories.some(
      (category: any) => category.id === selectedCategory,
    ),
  );
};

interface IHome {
  error: boolean;
  onGetCoupons: () => void;
}

const Home: FC<IHome> = ({ onGetCoupons, error }) => {
  const { groupCoupons, couponList, currentShopCategory } =
    useSelector((state: RootState) => state.couponsAndCategoriesList);

  useEffect(() => {
    if (
      localStorage.getItem('access_key_is_distributed') === 'true'
    ) {
      onGetCoupons();
    }
  }, []);

  const filteredCouponsByCategories = useMemo(() => {
    if (groupCoupons.length) {
      return getFilteredCouponByCategories(
        Number(currentShopCategory),
        groupCoupons,
        couponList,
      );
    }
    return null;
  }, [currentShopCategory, groupCoupons]);

  return (
    <>
      <Helmet title="ПодароЧек — скидки, акции и подарки">
        <meta
          name="keywords"
          content="Скидки, Скидка, Кэшбэк, Карта, бесплатно, дней, 500, 5000, подарок, Доставка, Подарок, урока"
        />
        <meta
          name="description"
          content="Промокоды, скидки и акции. Экономьте на покупках в магазинах, супермаркетах и онлайн-сервисах. На сайте и в мобильном приложении."
        />
      </Helmet>
      {Boolean(error) && <>Ошибка загрузки...</>}
      {groupCoupons.length ? (
        <>
          <CouponFilter />
          <CouponList cardsGet4Click={filteredCouponsByCategories} />
        </>
      ) : (
        <div>Загрузка...</div>
      )}
    </>
  );
};

export default Home;

import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';
import { Button } from '@pofd-front/storybook/dist';
import TagManager from 'react-gtm-module';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import type { RootState } from 'store';
import {
  groupCouponsList,
  setAccessKey,
  setCouponsList,
  setShopCategories,
} from 'store/couponsAndCategoriesListSlice';

import Home from 'pages/home';
import CardPage from 'pages/card-page';
import SearchResultPage from 'pages/search-result-page';
import NotFound404 from 'pages/not-found-404';
import ForgotPass from 'pages/forgotPass';
import FeedbackPage from 'pages/feedback-page';
import PolicyPage from 'pages/policy-page';
import TermsPage from 'pages/terms-page';
import RedirectPage from 'pages/redirect-page';
import ShopPage from 'pages/shop-page';
import useCoupons from 'shared/hooks/use-coupons';
import ErrorModals from '../error-modals/error-modals';
import Iframe from '../iframe/iframe';
import SmartBanner from '../smart-banner/smart-banner';
import RequiredAuthModal from '../required-auth-modal/required-auth-modal';
import AuthModal from '../auth-modal/auth-modal';
import styles from './app.module.scss';
import AppFooter from '../app-footer/app-footer';
import AppHeader from '../app-header/app-header';
import { ICoupons } from '../../interfaces';
import Loader from '../loader/loader';
import useGetPosition from '../../shared/hooks/use-get-position';

const tagManagerArgs = {
  gtmId: 'GTM-M5SBVDD',
};

TagManager.initialize(tagManagerArgs);

const url: string = document.location.href;

interface IContentModal {
  val: string | null;
}

const ContentModal: FC<IContentModal> = ({ val }) => {
  switch (val) {
    case 'auth':
      return <AuthModal userSignUp={false} />;
    case 'reg':
      return <AuthModal userSignUp />;
    case 'rereg':
      return <RequiredAuthModal />;
    default:
      return <ErrorModals />;
  }
};

declare const window: any;

const { dataLayer } = window;

const App = () => {
  dataLayer.push({
    event: 'pageview',
  });

  const [floctoryFlag, setFloctoryFlag] = useState(false);
  const [ssid, setSsid] = useState('');
  const [bid, setBid] = useState('');
  const [currentUrl, setCurrentUrl] = useState('');
  const [scrollBtnFlag, setScrollBtnFlag] = useState(false);
  const [smartBannerFlag, setSmartBannerFlag] = useState(false);
  const dispatch = useDispatch();
  const selectModal = (state: RootState) => state.modal;
  const modal = useSelector(selectModal);
  const handleSmartBannerClose = () => {
    setSmartBannerFlag(false);
  };
  const { couponList, isCouponsLoading, isError } = useSelector(
    (state: RootState) => state.couponsAndCategoriesList,
  );
  const { getCouponsAndCategories } = useCoupons();
  const { currentUserPosition } = useGetPosition();
  const { posObjData } = useSelector(
    (state: RootState) => state.userPosition,
  );

  useEffect(() => {
    (async () => {
      await currentUserPosition();
    })();
  }, []);

  const handleGetCoupons = useCallback(async () => {
    const { coupons, shopCategories, accessKey } =
      await getCouponsAndCategories(
        posObjData.cityName,
        posObjData.regionCode,
        posObjData.regionName,
      );
    dispatch(setCouponsList(coupons));
    dispatch(setShopCategories(shopCategories));
    dispatch(setAccessKey(accessKey));
  }, [dispatch, getCouponsAndCategories, posObjData]);

  useEffect(() => {
    (async () => {
      if (!floctoryFlag) {
        await handleGetCoupons();
      }
    })();
  }, [posObjData]);

  useEffect(() => {
    (async () => {
      if (url.indexOf('ssid') !== -1 && url.indexOf('bid') !== -1) {
        setFloctoryFlag(true);
        setSsid(url.split('ssid=')[1].split('&')[0]);
        setBid(url.split('bid=')[1].split('&')[0]);
        setCurrentUrl(url);
      }
    })();
  }, []);

  const handleScrollBtnClick = useCallback(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    const windowScroll = () => {
      const scroll = window.scrollY;
      if (scroll > document.documentElement.clientHeight) {
        setScrollBtnFlag(true);
      } else {
        setScrollBtnFlag(false);
      }
    };
    window.addEventListener('scroll', windowScroll);
    return () => window.removeEventListener('scroll', windowScroll);
  }, []);

  useEffect(() => {
    const onResize = () => {
      if (window.innerWidth < 480) {
        setSmartBannerFlag(true);
      } else {
        setSmartBannerFlag(false);
      }
    };

    onResize();

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  const deleteDouble = useCallback(
    (arr: ICoupons[], id: number) =>
      arr.filter((item) => item.couponId !== id),
    [],
  );

  const setNewArr = useCallback(
    (id: number, array: ICoupons[]) =>
      array.filter((item) => item.shop.id === id),
    [],
  );

  const setNewCouponsList = useCallback((couponsList: ICoupons[]) => {
    let workArr = [...couponsList];
    const newArr: ICoupons[] = [];
    workArr.map((coupon) => {
      workArr = deleteDouble(workArr, coupon.couponId);
      // eslint-disable-next-line no-param-reassign
      const newCoupon = {
        ...coupon,
        couponSameStore: setNewArr(coupon.shop.id, workArr),
      };
      newArr.push(newCoupon);
      return null;
    });
    return newArr;
  }, []);

  useEffect(() => {
    if (couponList) {
      const uniqueCoupon: any = {};
      const uniqueCouponsList = setNewCouponsList(couponList).filter(
        // eslint-disable-next-line no-return-assign
        (coupon) =>
          !uniqueCoupon[coupon.shop.id] &&
          (uniqueCoupon[coupon.shop.id] = 1),
      );
      dispatch(groupCouponsList(uniqueCouponsList));
    }
  }, [couponList]);

  return (
    <>
      {smartBannerFlag ? (
        <SmartBanner onSmartBannerClose={handleSmartBannerClose} />
      ) : null}
      <div
        className={`${styles.wrapper} ${
          smartBannerFlag ? styles.indent : ''
        }`}
      >
        <Router>
          <AppHeader
            floctoryFlag={floctoryFlag}
            currentUrl={currentUrl}
            smartBannerFlag={smartBannerFlag}
          />
          {floctoryFlag ? (
            <Iframe ssid={ssid} bid={bid} />
          ) : (
            <div className={styles.main}>
              <Container>
                <Routes>
                  <Route
                    path="/"
                    element={
                      <Home
                        error={isError}
                        onGetCoupons={handleGetCoupons}
                      />
                    }
                  />
                  <Route
                    path="/gift/:id"
                    element={
                      <CardPage onGetCoupons={handleGetCoupons} />
                    }
                  />
                  <Route
                    path="/shop/:id"
                    element={
                      <ShopPage onGetCoupons={handleGetCoupons} />
                    }
                  />
                  <Route
                    path="/search/:val"
                    element={
                      <SearchResultPage
                        onGetCoupons={handleGetCoupons}
                      />
                    }
                  />
                  <Route path="*" element={<NotFound404 />} />
                  <Route
                    path="forgot-pass"
                    element={<ForgotPass />}
                  />
                  <Route path="feedback" element={<FeedbackPage />} />
                  <Route path="policy" element={<PolicyPage />} />
                  <Route path="terms" element={<TermsPage />} />
                  <Route
                    path="redirect_store"
                    element={<RedirectPage />}
                  />
                </Routes>
                {scrollBtnFlag && (
                  <div className={styles.app_btn}>
                    <Button
                      styleType="borderless"
                      type="button"
                      onClick={() => {
                        handleScrollBtnClick();
                      }}
                      disabled={false}
                      leftIcon="arrowUp"
                      product="ofd"
                      testId="authorization_info_modal_content-click-1"
                      size="large"
                      iconStroke
                    />
                  </div>
                )}
              </Container>
            </div>
          )}
          <AppFooter />
        </Router>
        {modal.modal ? <ContentModal val={modal.definition} /> : null}
        {isCouponsLoading && <Loader />}
      </div>
    </>
  );
};

export default App;

import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import styles from './shop-page.module.scss';
import CouponList from '../components/coupon-list/coupon-list';
import { RootState } from '../store';
import { ICoupons } from '../interfaces';

interface IShopPage {
  onGetCoupons: () => void;
}

const ShopPage: FC<IShopPage> = ({ onGetCoupons }) => {
  const { id } = useParams();
  const [currentShopCoupons, setCurrentShopCoupons] = useState<
    ICoupons[]
  >([]);
  const [category, setCategory] = useState<string>('');

  const { couponList } = useSelector(
    (state: RootState) => state.couponsAndCategoriesList,
  );

  useEffect(() => {
    if (
      localStorage.getItem('access_key_is_distributed') === 'true'
    ) {
      onGetCoupons();
    }
  }, []);

  useEffect(() => {
    const newArr: ICoupons[] = couponList.filter(
      (coupon) => coupon.shop.id === Number(id),
    );
    setCurrentShopCoupons(newArr);
  }, [id, couponList]);

  useEffect(() => {
    if (currentShopCoupons.length) {
      const categories: string[] = [];
      currentShopCoupons[0].shopCategories.forEach((cat) => {
        if (cat.id !== 159) {
          categories.push(cat.name);
        }
        return categories;
      });
      setCategory(categories.join(', '));
    }
  }, [currentShopCoupons]);

  return (
    <div>
      <Helmet
        title={`${
          currentShopCoupons.length
            ? `${currentShopCoupons[0].shopName} - все акции магазина`
            : 'Загрузка...'
        }`}
      >
        <meta
          name="keywords"
          content="Скидки, Скидка, Кэшбэк, Карта, бесплатно, дней, 500, 5000, подарок, Доставка, Подарок, урока,"
        />

        <meta name="description" content="Страница поиска" />
      </Helmet>
      <div className={styles['mt-4']}>
        {currentShopCoupons?.length ? (
          <>
            <div className={styles.shop_info}>
              <div>
                <div className={styles.shop_info_head}>
                  <div className={styles.pic}>
                    <img
                      src={currentShopCoupons[0].shopLogoLink}
                      alt="coupons logo"
                    />
                  </div>
                  <div>
                    <p className={`${styles['shop-name']}`}>
                      {currentShopCoupons[0].shopName}
                      <span />
                    </p>
                    <p className={`${styles.category}`}>
                      {category}
                      <span />
                    </p>
                  </div>
                </div>
                {currentShopCoupons[0].shopDescription && (
                  <div
                    className={styles.shop_info_desc}
                    dangerouslySetInnerHTML={{
                      __html: currentShopCoupons[0].shopDescription,
                    }}
                  />
                )}
              </div>
            </div>

            <h5 className={`${styles.title}`}>Все акции магазина</h5>
            <CouponList cardsGet4Click={currentShopCoupons} />
          </>
        ) : (
          <div>Загрузка...</div>
        )}
      </div>
    </div>
  );
};

export default ShopPage;

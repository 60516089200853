/* eslint-disable react/prop-types */
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@pofd-front/storybook/dist';

import Coupon from '../coupon/coupon';
import { declensionNum } from '../../utils';

import styles from './coupon-list.module.scss';
import { ICoupons } from '../../interfaces';

interface ICouponList {
  cardsGet4Click: ICoupons[];
}

const CouponList: FC<ICouponList> = ({ cardsGet4Click }) => (
  <div className={styles['mt-5']}>
    <ul className={`${styles.list} d-block d-sm-flex flex-wrap`}>
      {cardsGet4Click ? (
        cardsGet4Click.map((item) => (
          <li className={`${styles.item}`} key={item.couponId}>
            <Coupon data={item} />
            {item.couponSameStore && item.couponSameStore.length ? (
              <div className={styles.btn}>
                <Link to={`/shop/${item.shop.id}`}>
                  <Button
                    styleType="borderless"
                    rightIcon="arrowRight"
                    iconStroke
                    type="button"
                    disabled={false}
                    product="ofd"
                    testId="authorization_info_modal_content-click-1"
                    label={`Посмотреть все ${
                      item.couponSameStore.length + 1
                    } ${declensionNum(
                      item.couponSameStore.length + 1,
                      ['акция', 'акции', 'акций'],
                    )}`}
                    size="large"
                  />
                </Link>
              </div>
            ) : null}
          </li>
        ))
      ) : (
        <p>Нет данных</p>
      )}
    </ul>
  </div>
);

export default CouponList;

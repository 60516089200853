/* eslint-disable react/prop-types */
import classNames from 'classnames/bind';
import React, { FC, useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import closeIconGreen from '../../images/icons/close-icon-green.svg';
import { IShopCategories } from '../../interfaces';
import {
  setCurrentShopCategory,
  setNewShopCategories,
  setShopCategories,
} from '../../store/couponsAndCategoriesListSlice';

import styles from './coupon-filter.module.scss';
import { RootState } from '../../store';

const cx = classNames.bind(styles);

const CouponFilter: FC = () => {
  const [hasAllCategoriesShow, setHasAllCategoriesShow] =
    useState(false);
  const [mobileFlag, setMobileFlag] = useState(false);
  const rootEl = useRef(null);
  const ref: React.RefObject<HTMLElement> = rootEl;
  const dispatch = useDispatch();

  const { shopCategories, currentShopCategory, newShopCategories } =
    useSelector((state: RootState) => state.couponsAndCategoriesList);

  const handleAllCategoriesShow = () => {
    setHasAllCategoriesShow(true);
  };

  const handleAllCategoriesClose = () => {
    setHasAllCategoriesShow(false);
  };

  const handleAllCategoryClick = () => {
    dispatch(setCurrentShopCategory(null));
    if (hasAllCategoriesShow) {
      setHasAllCategoriesShow(false);
    }
  };

  useEffect(() => {
    const arr =
      newShopCategories.length > 4
        ? [...newShopCategories]
        : [...shopCategories];
    dispatch(
      setNewShopCategories(
        !hasAllCategoriesShow ? arr.slice(0, 4) : arr,
      ),
    );
  }, [shopCategories, hasAllCategoriesShow]);

  useEffect(() => {
    const onResize = () => {
      if (window.innerWidth > 768) {
        setMobileFlag(false);
        setHasAllCategoriesShow(false);
      } else {
        setMobileFlag(true);
        setHasAllCategoriesShow(true);
        ref.current?.scrollTo(0, 0);
      }
    };

    onResize();

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  const handleCategoryClick = (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    const target = e.target as HTMLInputElement;
    dispatch(setCurrentShopCategory(target.id));
    if (hasAllCategoriesShow && window.innerWidth > 768) {
      setHasAllCategoriesShow(false);
    }
    const currentIndex = shopCategories.findIndex(
      (item: { id: number }) => Number(item.id) === Number(target.id),
    );
    if (currentIndex !== -1 && hasAllCategoriesShow) {
      const arr = [...newShopCategories];
      arr.splice(0, 0, arr.splice(currentIndex, 1)[0]);
      dispatch(setNewShopCategories(arr));
      dispatch(setShopCategories(arr));
    }
    if (mobileFlag) {
      ref.current?.scrollTo(0, 0);
    }
  };

  return (
    <ul ref={rootEl} className={cx('list')}>
      <li
        className={cx('item', {
          'item-reduced': !hasAllCategoriesShow,
        })}
      >
        <button
          data-testid="coupon_filter-click-1"
          type="button"
          className={cx('button', {
            'button-default': currentShopCategory !== null,
            'button-active': currentShopCategory === null,
          })}
          onClick={handleAllCategoryClick}
          disabled={currentShopCategory === null}
        >
          Все предложения
        </button>
      </li>
      {newShopCategories.map(
        (item: IShopCategories, index: number) => (
          <li
            className={cx('item', {
              'item-reduced': !hasAllCategoriesShow,
            })}
            key={item.id}
          >
            <button
              data-testid={`coupon_filter-click-${index + 4}`}
              type="button"
              id={String(item.id)}
              className={cx('button', {
                'button-default':
                  Number(currentShopCategory) !== Number(item.id),
                'button-active':
                  Number(currentShopCategory) === Number(item.id),
              })}
              onClick={handleCategoryClick}
              disabled={
                Number(currentShopCategory) === Number(item.id)
              }
            >
              {item.name}
            </button>
          </li>
        ),
      )}
      {!mobileFlag ? (
        <li
          className={cx('item', 'item-hidden', {
            'item-visible': !hasAllCategoriesShow,
          })}
        >
          <button
            data-testid="coupon_filter-click-2"
            type="button"
            className={cx('button', 'button-default')}
            onClick={handleAllCategoriesShow}
          >
            Ещё
          </button>
        </li>
      ) : null}
      {mobileFlag || !hasAllCategoriesShow ? null : (
        <li>
          <button
            data-testid="coupon_filter-click-3"
            type="button"
            className={cx('button-close')}
            onClick={handleAllCategoriesClose}
          >
            <img src={closeIconGreen} alt="close-icon" />
          </button>
        </li>
      )}
    </ul>
  );
};

export default CouponFilter;
